<template>
	<div id="footer" class="mt-5">
		<Loader :state="loader_state"></Loader>
		<div class="row justify-content-between mx-0">
			<div class="col-md-4 px-5 py-5 footer_section">
				<p class="footer_title text-white text-truncate d-inline">
					ABOUT US
					<img src="../../assets/icons/About-US.svg" alt="" class="img-fluid about_icon ml-2" />
				</p>
				<p class="footer_content text-white mt-3 text-capitalize">
					Alhaq is an education project that gathers and hosts free online content already published and available on
					social media by distinguished speakers and authors around the world to inspire and educate the Muslims. All
					the content is public and free to use.
				</p>
				<div class="login_group w-100">
					<img src="../../assets/icons/admin.svg" v-b-modal.login_modal class="logo_icon" alt="admin_enter" />
					<b-button
						v-b-modal.disclaimer_modal
						class="footer_buttons rounded float-right text-decoration-none text-bold"
						size="sm"
						>Disclaimer</b-button
					>
				</div>
				<b-modal
					id="login_modal"
					ref="login_modal"
					title="Login"
					size="md"
					centered
					no-close-on-backdrop
					ok-title="Login"
					@ok="attemptLogin"
					hide-header
					content-class="modal_content"
				>
					<b-form class="text-center">
						<img src="../../assets/logos/Al-haq-Logo-selected.svg" class="text-center modal_logo_img" alt="" />
						<b-form-group class="text-left">
							<b-input
								class="login_input"
								autofocus
								v-model="login_cred.email"
								placeholder="email"
								type="email"
								required
								size="sm"
							></b-input>
							<span v-if="login_errors.email" class="text-danger">
								{{ login_errors.email ? login_errors.email[0] : null }}</span
							>
						</b-form-group>
						<b-form-group class="text-left">
							<b-input
								class="login_input"
								v-model="login_cred.password"
								placeholder="password"
								type="password"
								required
								size="sm"
								@keyup.enter="attemptLogin"
							></b-input>
							<span v-if="login_errors.password" class="text-danger">
								{{ login_errors.password ? login_errors.password[0] : null }}</span
							>
						</b-form-group>
					</b-form>
				</b-modal>
				<b-modal id="disclaimer_modal" title="Disclaimer" title-class="disclaimer_title" centered hide-footer size="lg">
					<ul>
						<li v-for="item in disclaimers">
							{{ item }}
						</li>
					</ul>
				</b-modal>
			</div>
			<div class="col-md-4 px-5 py-5 footer_section">
				<p class="footer_title text-white text-truncate d-inline">
					SUBSCRIBE
					<img src="../../assets/icons/subscribe.svg" alt="" class="subscribe_icon ml-2" />
				</p>
				<b-form class="subscribe_form mt-3">
					<b-form-group class="mb-2">
						<label class="text-white text-left my-0">Name</label>
						<b-form-input
							id="sub_name"
							v-model="alhaq_subscriber.name"
							class="subscribe_field text-white bg-transparent"
							size="sm"
						></b-form-input>
						<span v-if="errors.name ? errors.name : null" class="text-danger">{{ errors.name[0] }}</span>
					</b-form-group>

					<b-form-group class="m-0">
						<label class="text-white text-left my-0">Email</label>
						<b-form-input
							id="sub_email"
							v-model="alhaq_subscriber.email"
							class="subscribe_field text-white bg-transparent"
							size="sm"
						></b-form-input>
						<span v-if="errors.email ? errors.email : null" class="text-danger">{{ errors.email[0] }}</span>
					</b-form-group>

					<b-form-group class="text-center">
						<b-button
							:disabled="alhaq_subscriber.email === ''"
							class="footer_buttons mt-3 mx-auto py-1 text-bold"
							@click="addToMaillist"
							>Subscribe</b-button
						>
						<p v-if="success" class="text-success text-capitalize">{{ success }}</p>
					</b-form-group>
				</b-form>
			</div>
			<div class="col-md-4 px-5 py-5 footer_section">
				<p class="footer_title text-white text-truncate d-inline">
					DONATE
					<img src="../../assets/logos/donate.svg" alt="" class="donation_icon ml-2" />
				</p>
				<div class="donation_details mt-3 text-center">
					<img class="paypal_logo" src="../../assets/logos/paypal.svg" alt="paypal_logo" />
					<a
						:class="{ stopEvent: donationLink === '' }"
						class="footer_buttons w-25 rounded text-decoration-none d-block px-3 py-1 mx-auto mt-3 text-bold"
						:href="donationLink.link ? donationLink.link : '/'"
						target="_blank"
						>Donate</a
					>
				</div>
			</div>
		</div>
		<div class="row bottom_most_footer_row mx-0">
			<div class="col-md-12 p-0">
				<p class="text-center text-white bottom_most_footer m-0 py-2">
					Copyright 2020. Designed by Sigma IT Consultants
				</p>
			</div>
		</div>

		<b-alert
			class="subscriptionAlert"
			v-model="showAlert"
			:variant="alertError ? 'danger' : 'success'"
			fade
			dismissible
		>
			{{ subscriptionSuccess }}
		</b-alert>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import Loader from '../shared/alhaq_loader.vue'
export default {
	components: {
		Loader,
	},
	data() {
		return {
			alhaq_subscriber: {
				name: '',
				email: '',
			},
			errors: {},
			subscriptionSuccess: '',
			loader_state: false,
			success: '',
			showAlert: false,
			alertError: false,
			donationLink: '',
			login_cred: {
				email: '',
				password: '',
			},
			login_errors: {},
			disclaimers: [
				'Alhaq is not responsible for any content linked to or referred to from this website.',
				'Alhaq is not responsible for any views and opinions of the speakers and authors hosted on this website.',
				'Alhaq does not accept the responsibility for content hosted on third party / social media websites.',
				'Alhaq is not responsible for any content uploaded to YouTube, Soundcloud or any other online video/audio hosting/streaming service embedded on our site.',
				'Alhaq does not necessarily agree with or condone the ideologies, messages, theological opinions and views of the speakers.',
				'Content on this site may be downloaded and used for personal use only. You may not use these files for commercial purposes as many of these files have rules and regulations that prevent their sale except by the publishing companies.',
				'Alhaq is not affiliated with any particular movement, sect, group, etc. We absolutely condemn in the strongest terms terrorism and any extremism done in the name of Islam and we refuse to associate ourselves with those who practice and condone such behavior and thoughts',
				'Our RSS feeds may not be used for development of third party apps or otherwise, without explicit permission from Alhaq.',
			],
		}
	},
	created() {
		this.getDonation().then((response) => {
			this.donationLink = response.data.data[0] ? response.data.data[0] : ''
		})
	},
	methods: {
		addToMaillist() {
			this.loader_state = true
			this.perform_subscribe(this.alhaq_subscriber).then((response) => {
				this.loader_state = false
				if (response.data.status === 'subscribed') {
					this.showAlert = true
					this.alertError = false
					this.errors = ''
					this.subscriptionSuccess =
						'Thankyou for subscribing. We’ll keep you posted with our latest content and updates'
					this.alhaq_subscriber = {}
				} else {
					this.showAlert = true
					this.alertError = true
					this.errors = ''
					this.subscriptionSuccess = response.data.detail
					this.alhaq_subscriber = {}
				}
			})
		},
		attemptLogin($event) {
			$event.preventDefault()
			this.loader_state = true
			this.login({ email: this.login_cred.email, password: this.login_cred.password })
				.then((response) => {
					if (response.data.error) {
						this.login_errors = response.data.data
					} else {
						this.login_errors = {}
						this.$refs['login_modal'].hide()
						this.$cookies.set('api_token', response.data.data.api_token, '3d')
						this.$nextTick(() => {
							this.$router.push({ name: 'dashboard-view' })
						})
					}
				})
				.finally(() => {
					this.loader_state = false
				})
		},
		...mapActions(['perform_subscribe', 'login', 'getDonation']),
	},
	watch: {
		errors: function () {
			if (Object.values(this.errors).length) {
				setTimeout(() => {
					this.errors = {}
				}, 5000)
			}
		},
		showAlert: function () {
			if (this.showAlert) {
				setTimeout(() => {
					this.showAlert = false
				}, 5000)
			}
		},
	},
}
</script>

<style scoped>
.footer_title {
	font-family: brandFont;
	font-size: 20px;
	font-weight: 400;
}
.footer_title::before {
	content: '';
	margin-right: 15px;
	border-left: 8px solid #28abe1;
}
.footer_title::after {
	content: ' ';
	margin-top: 15px;
	display: block;
	border: 0.5px solid white;
}
.footer_content,
label {
	font-size: 14px;
}
.footer_buttons,
.subscribe_field {
	font-size: 14px;
	box-shadow: none !important;
	outline: none !important;
}
.footer_buttons {
	background-color: white !important;
	font-weight: bolder !important;
	color: black !important;
}
.footer_buttons:hover {
	background-color: #28abe1 !important;
	color: white !important;
}
.footer_section {
	background-color: #080717 !important;
}
.bottom_most_footer_row {
	box-shadow: 0px -8px 8px #0d1319 !important;
}
.bottom_most_footer {
	position: relative;
	z-index: 2;
	font-size: 11px;
	background-color: #15212d;
	font-family: brandFont-subcat;
}
.about_icon,
.subscribe_icon,
.donation_icon {
	width: 40px !important;
	height: 40px !important;
}
.subscribe_field {
	border-radius: 0 !important;
	box-shadow: none !important;
}
.paypal_logo {
	width: 100px;
	height: 100px;
}

.logo_icon {
	background-color: transparent;
	width: 20px;
	height: 20px;
}
.login_group {
	vertical-align: middle;
}
.modal_logo_img {
	width: 150px;
	height: 150px;
}
.subscriptionAlert {
	position: fixed;
	bottom: 20px;
	right: 20px;
}
.stopEvent {
	pointer-events: none;
}
</style>
