import Vue from 'vue'
import Cookie from 'vue-cookies'
import Vuex from 'vuex'
import axios from 'axios'
import config from '../assets/config'

Vue.use(Vuex);

const public_http = axios.create();
const private_http = axios.create();
const youtube_http = axios.create();


private_http.defaults.baseURL = config.apiURL

public_http.defaults.baseURL =  config.apiURL

private_http.interceptors.request.use(req => {
    let header = { 'Authorization': 'Bearer '+ Cookie.get('api_token')}
    req.headers = header
    return req
})

export default new Vuex.Store({
    state: {
        contentData: [],
        speakers: [],
        categoryData: [],
        subCategoryData: [],
        sideBarData: [],
        languages: [],
        socials: [],
        webServices: [],
        sliders: [],
        authors: [],
        playlists: [],
        audios: [],
        apps: [],
        books: [],
        general_videos: [],
        articles: [],
        only_audios: [],
        user: '',

        subCatPlaylists: [],
        subCatAudioCollection: [],
        subCatBooks: [],
        subCatArticles: [],
        subCatApps: [],

        playlistYoutube: {
            total: '',
            data: []
        },

        AllArticles: [],
        AllGenVideos: [],
        AllPlaylists: [],
        AllApps: [],
        AllBooks: [],
        AllSpeakers: [],
        AllLanguages: [],
        AllAuthors: [],
        AllCategories: [],
        AllSubCategories: [],
        AllAudioCollections: [],
        AllAudios: [],

    },
    getters: {
        socialData: state => {
            return state.socials
        },
        webServiceData: state => {
            return state.webServices
        },
        sliderData: state => {
            return state.sliders
        },
        speakerData: state => {
            return state.speakers
        },
        authorData: state => {
            return state.authors
        },
        languageData: state => {
            return state.languages
        },
        ContentData: state => {
            return state.contentData
        },
        CategoryData: state => {
            return state.categoryData
        },
        SubCategoryData: state => {
            return state.subCategoryData
        },
        SideBarData: state => {
            return state.sideBarData;
        },
        PlaylistsData: state => {
            return state.playlists
        },
        AudiosData: state => {
            return state.audios
        },
        OnlyAudiosData: state=> {
            return state.only_audios
        },
        GenVideosData: state => {
            return state.general_videos
        },
        ArticlesData: state => {
            return state.articles
        },
        BooksData: state => {
            return state.books
        },
        subCatPlaylistsData: state => {
            return state.subCatPlaylists;
        },
        subCatAudioCollectionsData: state => {
            return state.subCatAudioCollection;
        },
        subCatAppsData: state => {
            return state.subCatApps;
        },
        subCatBooksData: state => {
            return state.subCatBooks;
        },
        subCatArticlesData: state => {
            return state.subCatArticles;
        },
        playlistYoutubeData: state => {
            return state.playlistYoutube;
        },
        AppsData: state => {
            return state.apps;
        },

        AllArticlesData: state => {
            return state.AllArticles
        },
        AllGenVideosData: state => {
            return state.AllGenVideos
        },
        AllPlaylistsData: state => {
            return state.AllPlaylists
        },
        AllAppsData: state => {
            return state.AllApps
        },
        AllBooksData: state => {
            return state.AllBooks
        },
        AllSpeakersData: state => {
            return state.AllSpeakers
        },
        AllLanguagesData: state => {
            return state.AllLanguages
        },
        AllAuthorsData: state => {
            return state.AllAuthors
        },
        AllCategoriesData: state => {
            return state.AllCategories
        },
        AllSubCategoriesData: state => {
            return state.AllSubCategories
        },
        AllAudioCollectionsData: state => {
            return state.AllAudioCollections
        },
        AllAudiosData: state => {
            return state.AllAudios
        },
        UserData: state => {
            return state.user
        },
    },
    mutations: {
        setSocialData: (state, payload) => {
            state.socials = payload
        },
        setWebServiceData: (state, payload) => {
            state.webServices = payload
        },
        setSliderData: (state, payload) => {
            state.sliders = payload
        },
        setSpeakerData: (state, payload) => {
            state.speakers = payload
        },
        setAuthorsData: (state, payload) => {
            state.authors = payload
        },
        setLanguageData: (state, payload) => {
            state.languages = payload
        },
        setContentData: (state, payload) => {
            state.contentData = payload
        },
        setCategoryData: (state, payload) => {
            state.categoryData = payload
        },
        setSideBarData: (state, payload) => {
            state.sideBarData = payload;
        },
        setSubCategoryData: (state, payload) => {
            state.subCategoryData = payload
        },
        setAudioData: (state, payload) => {
            state.audios = payload
            state.contentData = payload
        },
        setOnlyAudioData: (state, payload) => {
           state.only_audios = payload
        },
        setPlaylistData: (state, payload) => {
            state.playlists = payload
            state.contentData = payload
        },
        setGenVideoData: (state, payload) => {
            state.general_videos = payload
            state.contentData = payload
        },
        setArticlesData: (state, payload) => {
            state.articles = payload
            state.contentData = payload
        },
        setBooksData: (state, payload) => {
            state.books = payload
            state.contentData = payload
        },

        // sub-cat-items-details

        setSubCatPlaylists: (state, payload) => {
            state.subCatPlaylists = payload;
        },
        setSubCatAudioCollection: (state, payload) => {
            state.subCatAudioCollection = payload;
        },
        setSubCatBooks: (state, payload) => {
            state.subCatBooks = payload;
        },
        setSubCatArticles: (state, payload) => {
            state.subCatArticles = payload;
        },
        setSubCatApps: (state, payload) => {
            state.subCatApps = payload;
        },
        setPlaylistYoutubeData: (state, payload) => {
            state.playlistYoutube.total = payload.pageInfo.totalResults;
            state.playlistYoutube.data = payload.items;
        },
        setApps: (state, payload) => {
            state.apps = payload;
        },


        setAllArticles: (state, payload) => {
            state.AllArticles = payload
        },
        setAllGenVideos: (state, payload) => {
            state.AllGenVideos = payload
        },
        setAllPlaylists: (state, payload) => {
            state.AllPlaylists = payload
        },
        setAllApps: (state, payload) => {
            state.AllApps = payload
        },
        setAllBooks: (state, payload) => {
            state.AllBooks = payload
        },
        setAllSpeakers: (state, payload) => {
            state.AllSpeakers = payload
        },
        setAllLanguages: (state, payload) => {
            state.AllLanguages = payload
        },
        setAllAuthors: (state, payload) => {
            state.AllAuthors = payload
        },
        setAllCategories: (state, payload) => {
            state.AllCategories = payload
        },
        setAllSubCategories: (state, payload) => {
            state.AllSubCategories = payload
        },
        setAllAudioCollections: (state, payload) => {
            state.AllAudioCollections = payload
        },
        setAllAudios: (state, payload) => {
            state.AllAudios = payload
        },
        setUser: (state,payload) => {
            state.user = payload
        }
    },
    actions: {
        // aliraza code start
        // ---------------------------- ADD CATEGORY  -----------------------------------------
        addCategory: ({}, payload) => {
            return private_http.post('/category', payload).then(response => {
                return response
            }).catch(error => {
                return error.response
            })
        },
        // ---------------------------- UPDATE CATEGORY  ----------------------------------------- 
        updateCategory: ({}, payload) => {
            return private_http.post('/category/update', payload).then(response => {
                return response
            }).catch(error => {
                return error.response
            })
        },
        // ---------------------------- DELETE CATEGORY  -----------------------------------------
        deleteCategory: ({}, payload) => {
            return private_http.delete('/category/delete', { params: { 'id': payload.id } }).then(response => {
                return response
            }).catch(error => {
                return error.response
            })
        },
        // ---------------------------- ADD SUB CATEGORY  -----------------------------------------
        addSubCategory: ({}, payload) => {
            return private_http.post('/sub_cat', payload).then(response => {
                return response
            }).catch(error => {
                return error.response
            })
        },
        // ---------------------------- UPDATE SUB CATEGORY  ----------------------------------------- 
        updateSubCategory: ({}, payload) => {
            return private_http.post('/sub_cat/update', payload).then(response => {
                return response
            }).catch(error => {
                return error.response
            })
        },
        // ---------------------------- DELETE SUBCAT CATEGORY  -----------------------------------------
        deleteSubCategory: ({}, payload) => {
            return private_http.delete('/sub_cat/delete', { params: { 'id': payload.id } }).then(response => {
                return response
            }).catch(error => {
                return error.response
            })
        },
        // ---------------------------- ADD PLAYLIST  -----------------------------------------
        addPlaylist: ({}, payload) => { // adding video data to database
            return private_http.post('/playlist', payload.data).then(response => {
                return response
            }).catch(error => {
                return error.response
            })
        },
        updatePlaylist: ({}, payload) => { // updating playlist 
            return private_http.post('/playlist/update', payload.data).then(response => {
                return response
            }).catch(error => {
                return error.response
            })
        },
        updatePlaylistThumb: ({}, payload) => { // updating playlist
            return private_http.post('/playlist/update_thumb', payload).then(response => {
                return response
            }).catch(error => {
                return error.response
            })
        },
        // ---------------------------- DELETE PlayList CATEGORY  -----------------------------------------
        deletePlaylist: ({}, payload) => {
            return private_http.delete('/playlist/delete', { params: { 'id': payload.id } }).then(response => {
                return response
            }).catch(error => {
                return error.response
            })
        },
        // ----------------------------  for getting single playlist by id -----------------------------------------
        getSinglePlayListWithId: ({ commit }, payload) => { // 
            return public_http.get('/playlist', { params: { 'id': payload.id } }).then(response => {
                return response.data.data;
            }).catch(error => {
                return error.response
            })
        },
        // -------------------------------------  ADDING LANGUAGE  -------------------------------------------- //
        addLanguage: ({}, payload) => {
            return private_http.post('/language', payload.data).then(response => {
                return response
            }).catch(error => {
                return error.response
            })
        },
        // -------------------------------------  UPDATE LANGUAGE  -------------------------------------------- //
        updateLanguage: ({}, payload) => {
            return private_http.post('/language/update', payload.data).then(response => {
                return response
            }).catch(error => {
                return error.response
            })
        },
        // ---------------------------- DELETE LANBUAGE  -----------------------------------------
        deleteLanguage: ({}, payload) => {
            return private_http.delete('/language/delete', { params: { 'id': payload.id } }).then(response => {
                return response
            }).catch(error => {
                return error.response
            })
        },
        // -------------------------------------  ADDING SPEAKER  -------------------------------------------- //
        addSpeaker: ({}, payload) => {
            return private_http.post('/speaker', payload.data).then(response => {
                return response
            }).catch(error => {
                return error.response
            })
        },
        // -------------------------------------  UPDATE SPEAKER  -------------------------------------------- //
        updateSpeaker: ({}, payload) => {
            return private_http.post('/speaker/update', payload.data).then(response => {
                return response
            }).catch(error => {
                return error.response
            })
        },
        // ---------------------------- DELETE SPEAKER  -----------------------------------------
        deleteSpeaker: ({}, payload) => {
            return private_http.delete('/speaker/delete', { params: { 'id': payload.id } }).then(response => {
                return response
            }).catch(error => {
                return error.response
            })
        },
        // -------------------------------------  ADDING AUTHOR  -------------------------------------------- //
        addAuthor: ({}, payload) => {
            return private_http.post('/author', payload.data).then(response => {
                return response
            }).catch(error => {
                return error.response
            })
        },
        // -------------------------------------  UPDATE AUTHOR  -------------------------------------------- //
        updateAuthor: ({}, payload) => {
            return private_http.post('/author/update', payload.data).then(response => {
                return response
            }).catch(error => {
                return error.response
            })
        },
        // ---------------------------- DELETE AUTHOR  -----------------------------------------
        deleteAuthor: ({}, payload) => {
            return private_http.delete('/author/delete', { params: { 'id': payload.id } }).then(response => {
                return response
            }).catch(error => {
                return error.response
            })
        },
        // -------------------------------------  GET SOCIAL  -------------------------------------------- //
        getSocial: ({ commit }, payload) => {
            public_http.get('/social').then(response => {
                commit('setSocialData', response.data.data)
                    //return response
            }).catch(error => {
                return error.response
            })
        },
        // -------------------------------------  ADDING SOCIAL  -------------------------------------------- //
        addSocial: ({}, payload) => {
            return private_http.post('/social', payload.data).then(response => {
                return response
            }).catch(error => {
                return error.response
            })
        },
        // ---------------------------- DELETE SOCIAL  -----------------------------------------
        deleteSocial: ({}, payload) => {
            return private_http.delete('/social/delete', { params: { 'id': payload.id } }).then(response => {
                return response
            }).catch(error => {
                return error.response
            })
        },
        // -------------------------------------  GET WEB SERVICE  -------------------------------------------- //
        getWebService: ({ commit }, payload) => {
            return private_http.get('/web_service').then(response => {
                commit('setWebServiceData', response.data.data)
                return response
            }).catch(error => {
                return error.response
            })
        },
        // -------------------------------------  ADDING WEB SERVICE  -------------------------------------------- //
        addWebService: ({}, payload) => {
            return private_http.post('/web_service', payload.data).then(response => {
                return response
            }).catch(error => {
                return error.response
            })
        },
        // ---------------------------- DELETE WEB SERVICE  -----------------------------------------
        deleteWebService: ({}, payload) => {
            return private_http.delete('/web_service/delete', { params: { 'id': payload.id } }).then(response => {
                return response
            }).catch(error => {
                return error.response
            })
        },
        // -------------------------------------  GET SLIDER  -------------------------------------------- //
        getSlider: ({ commit }, payload) => {
             return public_http.get('/slider').then(response => {
                commit('setSliderData', response.data.data)
                return response; 
            }).catch(error => {
                return error.response
            })
        },
        // -------------------------------------  ADDING SLIDER  -------------------------------------------- //
        addSlider: ({}, payload) => {
            return private_http.post('/slider', payload.data).then(response => {
                return response
            }).catch(error => {
                return error.response
            })
        },
        // ---------------------------- DELETE SLIDER  -----------------------------------------
        deleteSlider: ({}, payload) => {
            return private_http.delete('/slider/delete', { params: { 'id': payload.id } }).then(response => {
                return response
            }).catch(error => {
                return error.response
            })
        },
        // aliraza code ends
        getPlaylistsYoutube: ({ commit }, payload = null) => {
            return youtube_http.get(payload.pl_link, {
                    params: {
                        playlistId: payload.pl_id,
                        key: payload.api_key,
                        part: 'contentDetails,snippet',
                        maxResults: '50'
                    }
                }).then(response => response)
                .catch(error => error.response)
        },
        getPlaylistsWithId: ({ commit }, payload) => {
            return public_http.get('/playlist', { params: { 'id': payload ? payload.id : null } })
                .then(response => response)
                .catch(error => error)
        },
        getPlaylists: ({ commit }, payload) => {
            public_http.get('/playlist', { params: { 'limit': payload ? payload.limit : null } }).then((response) => {
                commit('setPlaylistData', response.data.data)
            })
        },
        getAudioCollection: ({ commit }, payload = null) => {
            public_http.get('/audio_collection', { params: { 'limit': payload ? payload.limit : null, 'contentDetails': payload.contentDetails } }).then((response) => {
                commit('setAudioData', response.data.data)
            })
        },
        getAudioCollectionById: ({ commit }, payload = null) => {
            return public_http.get('/audio_collection', { params: { 'id': payload ? payload.id : null, 'contentDetails': payload.contentDetails} })
                .then(response => response)
                .catch(error => error)
        },
        getAudios: ({ commit }, payload = null) => {
            public_http.get('/audio', { params: { 'limit': payload ? payload.limit : null} }).then((response) => {
                commit('setOnlyAudioData', response.data.data)
            })
        },
        getVideos: ({ commit }, payload = null) => {
            public_http.get('/gen_videos', { params: { 'limit': payload ? payload.limit : null } }).then((response) => {
                commit('setGenVideoData', response.data.data)
            }).catch((error) => console.log(error))
        },
        getBooks: ({ commit }, payload = null) => {
            public_http.get('/books', { params: { 'limit': payload ? payload.limit : null } }).then((response) => {
                commit('setBooksData', response.data.data)
            })
        },
        getArticles: ({ commit }, payload = null) => {
            public_http.get('/articles', { params: { 'limit': payload ? payload.limit : null } }).then((response) => {
                commit('setArticlesData', response.data.data)
            })
        },
        getArticleById: ({ commit }, payload = null) => {
            return public_http.get('/articles', { params: { 'id': payload ? payload.id : null } })
                .then(response => response)
                .catch(error => error)
        },
        getCategories: ({ commit }) => {
            public_http.get('/category',).then((response) => {
                commit('setCategoryData', response.data.data)
            })
        },
        getCategoryById: ({commit}, payload = null) => {
            return public_http.get('/category',{params: {'id' : payload ? payload.id : null}})
                .then((response) => response)
                .catch(error => error)
        },

        getSubCatWithCategoryId: ({ commit }, payload) => {
            return public_http.get('/category', { params: { 'id': payload.id } }).then(response => {
                return response;
            }).catch(error => {
                return error.response
            })
        },
        getCategory: ({ commit }, payload) => {
            public_http.get('/category', { params: { 'with_subcat': payload.with_subcat } }).then(response => {
                commit('setSideBarData', response.data.data);
            }).catch(error => {
                return error.response
            })
        },
        getSubCategory: ({ commit }) => {
            public_http.get('/sub_cat', ).then(response => {
                commit('setSubCategoryData', response.data.data);
                return response;
            }).catch(error => {
                return error.response
            })
        },
        getSubCategoryWithId: ({ commit }, payload) => {
            return public_http.get('/sub_cat', { params: { 'id': payload.id } }).then(response => {
                return response;
            }).catch(error => {
                return error.response
            })
        },
        getLanguages: ({ commit }) => {
            public_http.get('/language').then(response => {
                commit('setLanguageData', response.data.data)
            }).catch(error => {
                return error.message
            })
        },
        getSpeakers: ({ commit }) => {
            public_http.get('/speaker').then(response => {
                commit('setSpeakerData', response.data.data)
                commit('setAllSpeakers', response.data.data)
            }).catch(error => {
                return error.message
            })
        },
        getAuthors: ({ commit }) => {
            public_http.get('/author').then(response => {
                commit('setAuthorsData', response.data.data)
            }).catch(error => {
                return error.message
            })
        },
        getSubCatItem: ({ commit }, payload) => {
            return public_http.get('/sub_cat', { params: { 'id': payload.id, contentDetails: true, list: payload.list } }).then(response => {
                commit('setSubCatPlaylists', response.data.data.playlists);
                commit('setSubCatAudioCollection', response.data.data.audio_collections);
                commit('setSubCatBooks', response.data.data.books);
                commit('setSubCatApps', response.data.data.apps);
                commit('setSubCatArticles', response.data.data.articles);
                return;
            }).catch(error => {
                console.log(error.response);
            })
        },
        getApps: ({ commit }) => {
            public_http.get('/apps').then(response => {
                commit('setApps', response.data.data);
            }).catch(error => {
                console.log(error);
            })
        },

        //------------------------------- PRIVATE INDEX ALL ACTIONS -------------------------------//
        getAllArticles: ({commit}) => {
             private_http.get('/article/all')
                .then(response => {
                    commit('setAllArticles', response.data.data)
                })
                .catch(error => error)
        },
        getAllGenVideos: ({commit}) => {
             private_http.get('/gen_video/all')
                .then(response => {
                    commit('setAllGenVideos', response.data.data)
                })
                .catch(error => error)
        },
        getAllPlaylists: ({commit}) => {
             private_http.get('/playlist/all')
                .then(response => {
                    commit('setAllPlaylists', response.data.data)
                })
                .catch(error => error)
        },
        getAllApps: ({commit}) => {
                   private_http.get('/app/all')
                .then(response => {
                    commit('setAllApps', response.data.data)
                })
                .catch(error => error)
        },
        getAllBooks: ({commit}) => {
                   private_http.get('/book/all')
                .then(response => {
                    commit('setAllBooks', response.data.data)
                })
                .catch(error => error)
        },
        getAllSpeakers: ({commit}) => {
                   private_http.get('/speaker/all')
                .then(response => {
                    commit('setAllSpeakers', response.data.data)
                })
                .catch(error => error)
        },
        getAllLanguages: ({commit}) => {
                   private_http.get('/language/all')
                .then(response => {
                    commit('setAllLanguages', response.data.data)
                })
                .catch(error => error)
        },
        getAllAuthors: ({commit}) => {
                   private_http.get('/author/all')
                .then(response => {
                    commit('setAllAuthors', response.data.data)
                })
                .catch(error => error)
        },
        getAllCategories: ({commit}) => {
                   private_http.get('/category/all')
                .then(response => {
                    commit('setAllCategories', response.data.data)
                })
                .catch(error => error)
        },
        getAllSubCategories: ({commit}) => {
                   private_http.get('/sub_cat/all')
                .then(response => {
                    commit('setAllSubCategories', response.data.data)
                })
                .catch(error => error)
        },
        getAllAudioCollections: ({commit}, payload) => {
                   private_http.get('/audio_collection/all', {params: {'contentDetails': payload.contentDetails}})
                .then(response => {
                    commit('setAllAudioCollections', response.data.data)
                })
                .catch(error => error)
        },
        getAllAudios: ({commit}) => {
                   private_http.get('/audio/all')
                .then(response => {
                    commit('setAllAudios', response.data.data)
                })
                .catch(error => error)
        },
        getCurrentUser: ({commit}) => {
             return private_http.get('/current-user')
                .then(response => {
                    commit('setUser', response.data.data);
                    return response;
                })
                .catch(error => error)
        },













        //------------------------------- USER ACTIONS -------------------------------//

            login: ({commit},payload) => {
                return public_http.post('/login', payload)
                .then(response => {
                    return response;
                })
                .catch(error => error)
            },

        resetPassword: (context, payload) => {
            return private_http.post('/reset-password', payload)
                .then(response => response)
                .catch(error => error)
        },
        updateUserThumb: (context, payload) => {
            return private_http.post('/users/update_thumb', payload)
                .then(response => response)
                .catch(error => error)
        },



        //------------------------------- ARTICLE ACTIONS -------------------------------//

        storeArticle: (context, payload) => {
            return private_http.post('/article', payload)
                .then(response => response)
                .catch(error => error)
        },

        updateArticle: (context, payload) => {
            return private_http.post('/article/update', payload)
                .then(response => response)
                .catch(error => error)
        },

        updateArticleThumb: (context, payload) => {
            return private_http.post('/article/update_thumb', payload)
                .then(response => response)
                .catch(error => error)
        },

        deleteArticle: (context, payload) => {
            return private_http.delete('/article/delete', { params: { id: payload.id } })
                .then(response => response)
                .catch(error => error)
        },



        //------------------------------- GENERAL VIDEO ACTIONS -------------------------------//

        storeVideo: (context, payload) => {
            return private_http.post('/gen_video', payload)
                .then(response => response)
                .catch(error => error)
        },

        updateVideo: (context, payload) => {
            return private_http.post('/gen_video/update', payload)
                .then(response => response)
                .catch(error => error)
        },

        updateVideoThumb: (context, payload) => {
            return private_http.post('/gen_video/update_thumb', payload)
                .then(response => response)
                .catch(error => error)
        },

        deleteVideo: (context, payload) => {
            return private_http.delete('/gen_video/delete', { params: { id: payload.id } })
                .then(response => response)
                .catch(error => error)
        },



        //------------------------------- GENERAL VIDEO ACTIONS -------------------------------//

        storeAudioC: (context, payload) => {
            return private_http.post('/audio_collection', payload)
                .then(response => response)
                .catch(error => error)
        },

        updateAudioC: (context, payload) => {
            return private_http.post('/audio_collection/update', payload)
                .then(response => response)
                .catch(error => error)
        },

        updateAudioCThumb: (context, payload) => {
            return private_http.post('/audio_collection/update_thumb', payload)
                .then(response => response)
                .catch(error => error)
        },

        deleteAudioC: (context, payload) => {
            return private_http.delete('/audio_collection/delete', { params: { id: payload.id } })
                .then(response => response)
                .catch(error => error)
        },

        //------------------------------- GENERAL VIDEO ACTIONS -------------------------------//

        storeAudio: (context, payload) => {
            return private_http.post('/audio', payload)
                .then(response => response)
                .catch(error => error)
        },

        updateAudio: (context, payload) => {
            return private_http.post('/audio/update', payload)
                .then(response => response)
                .catch(error => error)
        },

        deleteAudio: (context, payload) => {
            return private_http.delete('/audio/delete', { params: { id: payload.id } })
                .then(response => response)
                .catch(error => error)
        },


        //------------------------------- APPS ACTIONS -------------------------------//
        storeApps: (context, payload) => {
            return private_http.post('/app', payload)
                .then(response => response)
                .catch(error => error)
        },
        updateApps: (context, payload) => {
            return private_http.post('/app/update', payload)
                .then(response => response)
                .catch(error => error)
        },
        updateAppThumb: (context, payload) => {
            return private_http.post('/app/update_thumb', payload)
                .then(response => response)
                .catch(error => error)
        },
        deleteApps: (context, payload) => {
            return private_http.delete('/app/delete', { params: { id: payload.id } })
                .then(response => response)
                .catch(error => error)
        },
        //------------------------------- BOOK ACTIONS -------------------------------//
        storeBook: (context, payload) => {
            return private_http.post('/book', payload)
                .then(response => response)
                .catch(error => error)
        },
        updateBook: (context ,payload) => {
            return private_http.post('/book/update', payload)
                .then(response => response)
                .catch(error => error)
        },
        deleteBook: (context, payload) => {
            return private_http.delete('/book/delete', { params: { id: payload.id } })
                .then(response => response)
                .catch(error => error)
        },
        updateBookThumb: (context, payload) => {
            return private_http.post('/book/update_thumb', payload)
                .then(response => response)
                .catch(error => error)
        },

        //------------------------------- MISC ACTIONS -------------------------------//
        perform_subscribe: (context, payload) => {
            return public_http.post('/subscribe', payload)
                .then(response => response)
                .catch(error => error)
        },
        perform_search: (context, payload) => {
            return public_http.post('/search', payload)
                .then(response => response)
                .catch(error => error)
        },

        getDonation: (context, payload) => {
            return private_http.get('/donation')
            .then(response => response)
            .catch(error => error)
        },
        storeDonation: (context, payload) => {
            return private_http.post('/donation', payload)
            .then(response => response)
            .catch(error => error)
        },
        deleteDonation: (context, payload) => {
            return private_http.delete('/donation/delete', {params: {'id': payload}})
            .then(response => response)
            .catch(error => error)
        },

    }
})