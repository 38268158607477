import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'vue-cookies'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'home',
        component: () => import('./views/home-view')
    },
    {
        path: '/content-list/:title',
        name: 'content-list',
        component:  () => import('./views/content-list-view.vue')
    },
    {
        path: '/sub-category/:id',
        name: 'sub-category',
        component: () => import('./views/sub-category-view')
    },
    {
        path: '/sub-category/:subcat_id/playlist/:pl_id/:tab_index',
        name: 'watch-playlist',
        component: () => import('./views/watch-playlist-view')
    },
    {
        path: '/dashboard',
        name: 'dashboard-view',
        component: () => import( './views/dashboard-view.vue'),
        beforeEnter: ((to, from, next) => {
           
            if (isAuth()) {
                next(true)
            } else {
                next({ name: 'home' })
            }
        })

    },
    {
        path: '/sub-category/:subcat_id/listen-audio/:audioc_id/:tab_index',
        name: 'listen-audio',
        component: () => import('./views/listen-audio-view')
    },
    {
        path: '/sub-category/:subcat_id/read-article/:art_id/:tab_index',
        name: 'read-article',
        component:  () => import('./views/read-article-view')
    },
    {
        path: '/search-view/?search=:query',
        name: 'search-view',
        component:  () => import( './views/search-view')
    },



]

function isAuth () {
    const cookie = Cookies.get('api_token')
    if (cookie !== null && cookie !== undefined) {
        return true
    } else {
        return false
    }
}

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior (to, from, savedPosition) {
       return {x: 0, y: 0}
      }
})

export default router