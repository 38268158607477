const devURL = 'http://127.0.0.1:8000'
const prodURL = 'https://api.alhaq.world'

const hostAddress = process.env.NODE_ENV === 'development' ? devURL : prodURL

console.log(hostAddress)
export default {
	baseURL: `${hostAddress}/`,
	apiURL: `${hostAddress}/api`,
	shareURL: `${hostAddress}`,
}
