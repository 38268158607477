<template>
	<div id="topbar">
		<Loader :state="loader_state"></Loader>
		<div class="row">
			<div class="col-md-12 p-0">
				<b-navbar toggleable="lg" class="py-0">
					<b-navbar-brand href="#" class="p-0">
						<img src="../../assets/logos/Al-haq-Logo-selected.svg" class="logo" />
					</b-navbar-brand>
					<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
					<b-collapse id="nav-collapse" is-nav>
						<b-navbar-nav class="ml-auto">
							<b-nav-item-dropdown right no-caret toggle-class="text-decoration-none">
								<template v-slot:button-content>
									<img v-if="UserData.image" :src="baseURL + UserData.image" class="userImage" />
									<span v-else class="text-white border p-2">{{ UserData.email }}</span>
								</template>
								<b-dropdown-item v-b-modal.update_profile>Profile</b-dropdown-item>
								<b-dropdown-item @click="logout">Logout</b-dropdown-item>
							</b-nav-item-dropdown>
						</b-navbar-nav>
					</b-collapse>
				</b-navbar>
			</div>
		</div>
		<div>
			<b-modal
				@close="errors = {}"
				id="update_profile"
				title="Profile"
				title-class="text-info"
				header-class="article-header"
				body-class="article-body"
				size="md"
				centered
				no-close-on-esc
				no-close-on-backdrop
				hide-footer
			>
				<div class="row">
					<div class="col-md-12">
						<div class="text-center">
							<div v-if="!UserData.image">
								<b-form-file v-model="UserData.image" @input="update_profile_thumb"></b-form-file>
							</div>
							<div v-else>
								<b-icon-camera class="h1 camera-icon text-info" @click="UserData.image = null"></b-icon-camera>
								<img
									v-if="typeof UserData.image === 'string'"
									:src="baseURL + UserData.image"
									class="profile_thumb"
									alt="profile_thumb"
								/>
							</div>
							<!--                            <form>-->
							<div>
								<b-form-input placeholder="Name " class="mt-3" v-model="UserData.name" disabled></b-form-input>
							</div>
							<div>
								<b-form-input placeholder="Email " class="mt-3" v-model="UserData.email" disabled></b-form-input>
							</div>
							<hr />
							<div>
								<b-form-input
									type="password"
									placeholder="Password "
									class="mt-3"
									v-model="UserData.current_password"
								></b-form-input>
								<span
									v-if="this.errors.current_password"
									class="text-danger float-left"
									>{{...this.errors.current_password}}</span
								>
							</div>
							<div>
								<b-form-input
									type="password"
									placeholder="New Password "
									class="mt-3"
									v-model="UserData.new_password"
								></b-form-input>
								<span
									v-if="this.errors.new_password"
									class="text-danger float-left"
									>{{...this.errors.new_password}}</span
								>
							</div>
							<div>
								<b-form-input
									type="password"
									placeholder="Confirm Password "
									class="mt-3"
									v-model="UserData.confirm_password"
								></b-form-input>
								<span v-if="this.errors" class="text-danger float-left">{{...this.errors}}</span>
							</div>
							<!--                                </fieldset>-->
							<div>
								<b-button @click="changePassword()" class="mt-3 w-25 float-right" variant="info">Save</b-button>
							</div>
							<!--                            </form>-->
						</div>
					</div>
				</div>
			</b-modal>
		</div>
		<b-toast id="success-toast-top" title="Successfull !" variant="success">
			{{ success_message }}
		</b-toast>
	</div>
</template>

<script>
import config from '../../assets/config'
import Loader from '../shared/alhaq_loader'
import Cookie from 'vue-cookies'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
	name: 'topBar',
	components: { Loader },
	data() {
		return {
			loader_state: false,
			success_message: '',
			image: '',
			local_image: '',
			baseURL: config.baseURL,
			username: '',
			useremail: '',
			errors: [],
		}
	},
	mounted() {
		this.loader_state = true
		this.getCurrentUser()
		this.loader_state = false
	},
	computed: {
		...mapGetters(['UserData']),
	},
	methods: {
		...mapMutations(['setUser']),
		...mapActions(['resetPassword', 'updateUserThumb', 'getCurrentUser']),
		logout() {
			this.$cookies.remove('api_token')
			this.$router.push({ name: 'home' })
			window.localStorage.clear()
		},
		update_profile_thumb() {
			if (this.UserData.id) {
				let data = new FormData()
				data.append('id', this.UserData.id)
				data.append('image', this.UserData.image)
				this.loader_state = true
				this.updateUserThumb(data).then((response) => {
					if (response.data.error) {
						this.errors = response.data.data
						this.loader_state = false
					} else {
						this.setUser(response.data.data)
						this.loader_state = false
						this.$bvToast.show('success-toast-top')
					}
				})
			}
		},
		changePassword() {
			if (this.UserData.new_password !== this.UserData.confirm_password) {
				return this.errors.push('Confirm password and new passwords not matched')
			}
			let data = new FormData()
			data.append('id', this.UserData.id)
			data.append('current_password', this.UserData.current_password)
			data.append('new_password', this.UserData.new_password)
			this.loader_state = true
			this.resetPassword(data).then((response) => {
				if (response.data.error) {
					this.errors = response.data.data
					this.loader_state = false
				} else {
					this.success_message = response.data.message
					this.$bvModal.hide('update_profile')
					this.$bvToast.show('success-toast-top')
					window.localStorage.clear()
					Cookie.remove('api_token')
					this.$router.push({ name: 'home' })
					this.loader_state = false
				}
			})
		},
	},
}
</script>

<style scoped>
.userImage {
	border-radius: 50%;
	height: 50px;
	width: 50px;
}
.logo {
	height: 80px;
	width: 80px;
}
.navbar {
	border: 1px solid #15212d;
	background-color: #15212d;
}
#topbar {
	position: sticky;
	top: 0px;
	z-index: 2;
}
.profile_thumb {
	border-radius: 50%;
	width: 200px;
	height: 200px;
}
.camera-icon {
	position: absolute;
	top: 29%;
	left: 58%;
}
</style>
