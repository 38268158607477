<template>
    <div id="private_layout">
        <div class="container-fluid dashboard_container">
            <top-bar></top-bar>
            <div class="row justify-content-center">
                <div class="col-md-12 p-5">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import topBar from "../private/topBar";
    export default {
        components: {
            topBar
        }
    }
</script>

<style scoped>
    #private_layout {
        background-color: #1b2735;
        min-height: 100vh;
    }
</style>