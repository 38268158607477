<template>
        <b-sidebar id="main_sidebar" :no-header=true width="380px" v-model="is_showing" :backdrop="true">
            <Loader :state="loader_state"></Loader>
            <div class="row position-relative m-0">
                <div class="col-md-12">
                    <div class="row px-3 my-3 sidebar_header align-items-center">
                        <div class="col-md-6 col-6 px-0">
                            <img src="../../assets/logos/Al-haq-Logo-selected.svg" alt="" class="brand_logo float-left">
                        </div>
                        <div class="col-md-6 col-6">
                            <b-icon-x class="side_bar_close rounded-circle text-white float-right mr-5 h1" @click="is_showing= false"></b-icon-x>
                        </div>
                       
                    </div>
                    <div class="row px-3 my-4 sidebar_body">
                        <div class="col-md-12">
                            <div class="cat_group py-2" v-for="(item, index) in SideBarData" :key="index">
                                <div class="cat text-left w-100 sidebar_item_text" v-b-toggle="item.id.toString()">
                                    {{item.name}}
                                    <b-icon-plus class="float-right mr-5 sidebar_plus_icon h3 m-0"></b-icon-plus>
                                </div>
                                <b-collapse :id="item.id.toString()" accordion="true">
                                    <ul class="sub_cat_list my-2 pl-5">
                                        <li class="sub_cat_list-item py-2"
                                            v-for="(sub_cat, index) in item.sub_categories"
                                            :key="index"
                                            @click="$router.push({name: 'sub-category', params: {
                                            id: sub_cat.id,
                                            category: item.name,
                                            sub_category: sub_cat.name,
                                            sub_category_desc: sub_cat.description
                                        }})"

                                        >{{sub_cat.name}}</li>
                                    </ul>
                                </b-collapse>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-sidebar>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import Loader from '../shared/alhaq_loader'
    export default {
        components: { Loader },
        data () {
            return {
                loader_state: false,
                is_showing: false,
            }
        },
        mounted () {
            this.loader_state = true
            this.getCategory({with_subcat: true}).then( () => {
                this.loader_state = false
            });
        },
        methods: {
            ...mapActions([
                'getCategory',
            ]),
            toggleListItem (id) {
                document.getElementById(id);
            },
        },
        computed: {
            ...mapGetters([
                'SideBarData'
            ])
        }
    }

</script>

<style scoped>
    .sub_cat_list {
        list-style-type: none;
    }
    .cat_group {
        font-family: brandFont-cat;
    }
    .cat_group,
    .sub_cat_list-item
    {
        cursor: pointer;
    }
    .sub_cat_list-item {
        font-family: brandFont-subcat;
    }
    .side_bar_close {
        cursor: pointer;
        background-color: #28abe1;
        font-size: 30px;
        font-weight: bolder;
        opacity: 1 !important;
    }
    .brand_logo {
        cursor:pointer;
        width: 125px !important;
        height: 125px !important;
    }
    .router-link,
    .router-link:hover
    {
        text-decoration: none;
    }
    .sidebar_header {
        position: sticky;
        top: 1px;
        background-color: #15212d !important;
        z-index: 2 !important;
    }
    .b-sidebar > .b-sidebar-body {
        background-color: #15212d !important;
    }
    .sidebar_item_text {
        font-size: 14px;
        color: #ffffff;
        transition: color .3s ease-in-out;
    }
    .sidebar_item_text:hover {
        color: #28abe1;
    }
    .sidebar_item_text:focus {
        outline: none !important;
    }
    .sub_cat_list-item {
        font-size: 13px;
        color: #ffffff;
        transition: color .3s ease-in-out;
    }
    .sub_cat_list-item:hover {
        color: #28abe1;
    }
    .sidebar_footer {
        bottom: 0px;
        background-color: #15212d !important;
    }
   
</style>