<template>
    <div id="g_layout">
            <div class="row no-gutters">
                <div class="col-md-12">
                    <div id="headers">
                        <tm-bar></tm-bar>
                    </div>

                    <div id="content-window">
                        <router-view></router-view>
                    </div>

                    <div id="footers">
                        <g-footer></g-footer>
                    </div>
                </div>
            </div>
        <g-sidebar></g-sidebar>
    </div>
</template>

<script>
    import general_sidebar from "../public/general_sidebar";
    import generalFooter from "../public/generalFooter";
    import topMostBar from "../public/topMostBar";
    export default {
    
        components: {
            'g-sidebar': general_sidebar,
            'g-footer': generalFooter,
            'tm-bar': topMostBar,
        }
    }

</script>

<style>
    #g_layout {
        background-color: #1b2735 !important;
        min-height: 100vh;
    }
    #content-window {
        margin: auto;
        max-width: 1280px;
        background-color: #1b2735;
    }
    #footers {
        border: none !important;
        background-color: #1b2735;
    }
    @media only screen and (max-width:768px) {
        #content-window {
            margin: auto auto !important;
        }
    }
</style>