<template>
    <b-modal id="loader-modal" no-close-on-esc no-close-on-backdrop  content-class="loader_content" centered v-model="state" hide-header hide-footer size='xl'>
        <img class="loader_img" src="../../assets/logos/Al-haq-Logo-selected.svg"  type="svg" alt="logo_svg">
        <div class="loader_text">Please wait...</div>
    </b-modal>
</template>

<script>
 export default {
     props: {
         state: {
             default: false,
         }
     },
 }

</script>

<style scoped>

    .loader_img {
        text-align: center;
        margin: auto;
        width: 40%;
        height: auto;
    }
     .loader_img {
        animation: opac 3s ease-in-out infinite;
    }
    .loader_text {
        font-family: brandFont-subcat;
        font-size: 18px;
        color: white;
    }
    @keyframes opac {
        0% {opacity: .5;}
        75% {opacity: .9;}    
        100% {opacity: 1;}
    }

        
</style>