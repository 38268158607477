<template>
  <div id="topMostBar">
    <div class="row px-md-5 px-3 py-1 no-gutters">
      <div class="col-md-6 col-6">
          <div class="text-white my-1 my-md-0">
            <router-link :to="{name: 'home'}">
              <img src="../../assets/icons/home-icon.svg" class="my-0 mr-2 date_calendar" alt="home-icon" width="14px">
              <!-- <b-icon-house-door-fill class="my-0 mr-2 date_calendar"></b-icon-house-door-fill> -->
            </router-link>
              <!-- <b-icon-calendar-fill class="my-0 mr-2 date_calendar"></b-icon-calendar-fill> -->
              <span class="px-md-2 px-sm-2 px-0 top_dates">
                  <span class="date_en mr-2">{{date_en}}</span>
                  <span class="date_divider"> | </span>  
                  <span class="date_ar ml-2" dir='ltr'>{{ date_ar}}</span>
                </span>
          </div>
      </div>
      <div class="col-md-4 col-6">
        <div class="float-right">
          <p for="#dropdown-language" class="px-2 d-inline text-white language_label">Language</p>
            <b-button id="dropdown_language" class="bg-white text-dark px-2 px-sm-3 py-1 outline-none language_value">English</b-button>
        </div>
      </div>
      <div class="col-md-2 col-12">
          <div id="social" class="mt-3 mb-1 my-md-0 float-right">
              <a v-for="(item, index) in socialData" :key="index" :href="item.url" target="_blank">
                  <img :src="baseURL+item.icon" alt="social_fb" class="social_icon ml-3"/>
              </a>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import momentH from 'moment-hijri'
import {mapActions, mapGetters} from 'vuex'
import config from "../../assets/config";

export default {
    data () {
        return {
            date_en: moment().locale('en-us').format('D MMM dddd, YYYY'),
            date_ar: momentH().locale('sa-ar').format('iD iMMM dddd, iYYYY'),
            baseURL: config.baseURL
        }
    },
    mounted() {
      this.getSocial()
    },
    methods: {
        ...mapActions([
            'getSocial'
        ])
    },
    computed: {
        ...mapGetters([
            'socialData'
        ])
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  #topMostBar {
  padding: 0px 30px !important;
  background-color: #15212d;
  position: relative;
  box-shadow: 0px 8px 8px #0d1319 !important;
  z-index: 2 !important;
  }
  #topMostBar .row:first-child {
    max-width: 1280px;
    margin: auto;
  
  }
  #dropdown_language >>> button {
      background-color: white;
      color: black !important;
      font-weight: bolder;
  }
  .social_icon {
    height: 20px;
    width: 20px;
  }
   #topMostBar * {
    font-family: brandFont-cat !important;
  }
  .date_en {
    font-size: 11px;
  }
  .date_ar {
    font-size: 13px;
    font-weight: 600;
  }
  .date_calendar {
    color: white;
    font-size: 14px;
  }
  .date_calendar:hover {
    color: blue;
  }
  .date_divider {
    font-size: 11px;
  }
  .language_label,
  .language_value {
    font-size: 11px;
  }

  @media screen and (max-width:768px) {
    #topMostBar {
      padding: 0px !important;
      font-size: 11px;
    }
  }
</style>
