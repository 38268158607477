<template>
  <div id="app">
      <component :is="active_layout"></component>
      <b-icon icon="file-arrow-up" class="rounded-circle p-2 scroll_to_top bg-dark text-white" :class="{show_scroll: check_scroll_offset}" @click='scroll_to_top' ></b-icon>
  </div>

</template>

<script>
  import public_layout from "./components/layouts/public_layout";
  import private_layout from "./components/layouts/private_layout";
  export default  {
      data() {
        return {
            active_layout: ''
        }
      },
      methods: {
        scroll_to_top () {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
      },
      computed: {
        check_scroll_offset () {
           return true;
        }
      },
    watch: {
          '$route.name': {
              handler: function () {
                  if (this.$route.name == 'dashboard-view') {
                      this.active_layout = private_layout
                  } else {
                      this.active_layout  = public_layout
                  }
              },
              immediate: true
          }
    }
  }

</script>
<style>

    /*   application fonts */

    @font-face {
        font-family: brandFont;
        src: url("./assets/fonts/AkzidenzGrotesk.otf");
    }
    @font-face {
        font-family: brandFont-cat;
        src: url("./assets/fonts/Poppins/Poppins-SemiBold.ttf");
    }
    @font-face {
        font-family: brandFont-subcat;
        src: url("./assets/fonts/Poppins/Poppins-Light.ttf");
    }
    @font-face {
        font-family: brandFont-bold;
        src: url("./assets/fonts/Poppins/Poppins-Bold.ttf");
    }
    .b-sidebar-outer {
        background-color: #15212d !important;
    }
    .b-sidebar > .b-sidebar-body {
        background-color: #15212d !important;
        box-shadow: 0px 0px 10px 0px #0d1319 !important;
    }
    .ql-toolbar.ql-snow {
        background-color: #ffffff !important;
    }
    .ql-editor {
        min-height: 100% !important;
        height: max-content !important;
    }
    .ql-editor .ql-video {
        width: 100%;
        min-height: 300px !important;
        margin-left: auto;
        margin-right: auto;
    }
    .ql-editor img {
        width: 100%;
        min-height: auto !important;
        margin-left: auto;
        margin-right: auto;
    }
    .confirm-body, .confirm-header, .confirm-footer {
        border: none !important;
        background-color: #1b2735 !important;
        color: #28abe1;
    }
    #login_modal .modal-footer .btn {
        outline: none;
        color: black !important;
        background-color: #ffffff !important;
        font-size: 11px;
        text-transform: uppercase;
        font-family: brandFont !important;
    }
    #login_modal .modal-footer .btn:hover {
        background-color: #28abe1 !important;
        color: white !important;
    }
    #login_modal .modal-body .form-group .form-control {
        outline: 0 !important;
        box-shadow: 0 !important;
        color: white;
        border-radius: 0 !important;
        background-color: transparent !important;
        border: 1px solid #28abe1 !important;
    }
    #login_modal .modal_content , .modal-footer {
        border: none !important;
        background-color: #1b2735 !important;
    }
    .block_cards {
        transition: box-shadow .5s ease-in-out;
        transition: transform .5s ease-in-out;
    }
    .block_cards:hover {
        transform: scale(1.04);
        box-shadow: 0px 0px 8px 0px #28abe1;
    }
    .gen-video-header {
        background-color: #15212d !important;
        color: #28abe1 !important;
        font-family: brandFont-subcat !important;
        border: none !important;
    }
    .gen-video-footer .btn{
        display: none;
    }
    .gen-video-content {
        background-color: #15212d !important;
    }
    .alert_box {
        color: #28abe1;
        background-color: #15212d !important;
    }
    #pagination_controller {
        background-color: transparent !important;
        color: #28abe1;
        cursor: pointer;
    }
    ::-webkit-scrollbar {
        width: 10px;
        background-color: #15212d  !important;
    }
    ::-webkit-scrollbar-thumb {
       background-color: #0d1319;
    }
    #loader-modal .loader_content {
        text-align: center;
        background-color: transparent !important;
    }
    .scroll_to_top {
        display: none !important;
        z-index: 100;
        position: fixed;
        cursor: pointer;
        bottom: 10px;
        right: 10px;
        font-size: 40px !important;
        transition: font-size .1s;
    }
    .scroll_to_top:hover {
        font-size: 45px !important;
        background-color:#28abe1 !important;
    }
    .link:hover {
        border-bottom: 2px solid #28abe1 !important;
        cursor: pointer;

    }
    .show_scroll {
        display: block !important;
    }
    .pagination li.page-item.active .page-link {
        z-index: 1;
    }
    .disclaimer_title {
        color: #28abe1 !important;
    }

    ul.pagination.b-pagination >>> li.page-item {
        background-color: transparent !important;
    }

</style>
